<template>
  <div>
    <div class="header">
      <van-row>
        <van-col @click="onClickBack">
          <svg-icon
            :data_iconName="'back-default'"
            :className="'back_icon'"
            style="height: 25px"
          />
          <span style="font-size: 25px; color: white;font-weight:bold">消息通知详情</span>
        </van-col>
      </van-row>
    </div>
    <div style="height:90vh;overflow-y:scroll;padding:0 .2rem 2rem .2rem">
      <card style="margin-top:30px;">
        <template #content>
          <van-row style="text-align:center;">
            <span class="title_text">{{notification.title}}</span>
          </van-row>
          <van-row style="margin-top:30px" class="content_text">
            <span v-html="notification.content"></span>
          </van-row>
          <div v-if="notification.file_url !== '' && notification.file_url !== null">
              <van-divider dashed/>
              <div style="font-size: 15px;width: 100%;word-wrap: break-word;">
                附件： 
                <a :href="notification.file_url" style="color:#395EE8" 
                  v-if="notification.file_url !== null">
                  {{notification.file_url}}
                </a>
              </div>
            </div>
          <van-row class="title_info_text">
            <van-col span="12" style="text-align:left">
              <span>{{notification.author}}</span>
            </van-col>
            <van-col span="12" style="text-align:right">
              {{notification.created_at}}
            </van-col>
          </van-row>
        </template>
      </card>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex"
import card from '@/views/components/Card'
export default {
  name: "xiaoxiInfo",
  components: {
    card
  },
  data: () => ({
    tabIndex: 0
  }),
  computed: {
    ...mapGetters('xiaoxi', 
      {notification: 'getCurrentNotification'})
  },
  created() {
    const notificationId = this.$route.params.id
    this.tabIndex = this.$route.query.tabIndex
    this.$store.dispatch("xiaoxi/getNotificationById", notificationId)
  },
  methods: {
    onClickBack() {
      this.$router.push({path: '/xiaoxi/main', query: {tabIndex: this.tabIndex}})
    },
  }
}
</script>

<style>
.title_text {
  font-size: 16px;
  font-weight: bold;
}
.title_info_text {
  font-size: 14px;
  margin-top: 10px;
  text-align: center;
  color: #999999;
}
.content_text {
  color: #666666;
  font-size: 14px;
}
</style>